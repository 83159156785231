/* eslint no-restricted-globals: 0 */
import './header.css';
import React from 'react';
import CommandButton from './command-button';
import SchemaBrowser from './schema-browser';
import api from '../api';
import Icon from './icon';

export default () => {
  const onResetClick = async () => {
    if (
      !confirm(
        'Are you sure you want to reset the database? This will remove all data you have added.'
      )
    ) {
      return;
    }

    await api.resetInstance();
  };

  return (
    <div className="header">
      <SchemaBrowser />
      <CommandButton color="primary" onClick={onResetClick}>
        <Icon name="undo" /> Reset Db
      </CommandButton>
    </div>
  );
};
