import './table.scss';
import React from 'react';
import { Table } from 'reactstrap';

export default ({ queryResult: { rows, fields } }) => {
  const _rows = rows.slice(0, 300);

  const headers = fields.map(({ name }) => {
    return <th key={name}>{name}</th>;
  });

  const renderedRows = _rows.map((row, index) => {
    const tds = fields.map(({ name }) => {
      let val = row[name];

      if (typeof val === 'object') {
        val = JSON.stringify(val);
      } else if (typeof val === 'boolean') {
        val = val.toString();
      }

      return <td key={name}>{val}</td>;
    });

    return <tr key={index}>{tds}</tr>;
  });

  return (
    <div className="results-table">
      <Table className="table table-hover table-dark mb-0 table-striped">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>{renderedRows}</tbody>
      </Table>
    </div>
  );
};
