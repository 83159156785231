import './editor-footer.scss';
import React from 'react';
import { Button } from 'reactstrap';
import { format } from 'sql-formatter';
import Icon from './icon';

export default ({ runQuery, value, setValue }) => {
  return (
    <div className="footer">
      <Button color="primary" size="sm" onClick={() => runQuery(value)}>
        <Icon name="play" /> Run Query
      </Button>
      <Button color="primary" size="sm" onClick={() => setValue(format(value))}>
        <Icon name="align-left" /> Format
      </Button>
    </div>
  );
};
