import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const StickyNav = ({ links, selector }) => {
  const [visibles, setVisibles] = useState([]);

  useEffect(() => {
    const els = document.querySelectorAll(selector);

    const observers = Array.from(els).map((el, i) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setVisibles((vs) => {
            const _vs = [...vs];
            _vs[i] = entry.isIntersecting;
            return _vs;
          });
        },
        {
          rootMargin: '-10%',
          root: null,
          threshold: 0.01,
        }
      );
      observer.observe(el);

      return observer;
    });

    return () => {
      observers.map((o) => o.disconnect());
    };
  }, [links, selector]);

  let activeIndex = 0;
  for (let index in visibles) {
    if (visibles[index]) {
      activeIndex = parseInt(index);
      break;
    }
  }

  return (
    <ListGroup className="sticky-nav sticky-top">
      {links.map((link, i) => (
        <ListGroupItem
          active={i === activeIndex}
          href={`#${link}`}
          key={link}
          tag="a"
          action
        >
          {link}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default StickyNav;
