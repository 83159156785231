import React from 'react';
import Table from './table';

export default ({ results }) => {
  if (!results.length) {
    return null;
  }

  const [result] = results;

  if (!result) {
    return null;
  }

  return <Table queryResult={result} />;
};
