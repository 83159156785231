import React from 'react';
import ReactMd from 'react-markdown';
import { Alert, Card, CardBody, Table } from 'reactstrap';
import Editor from './sql-editor';

export default ({ children, title }) => {
  return (
    <Card className="note">
      <CardBody id={title}>
        <ReactMd
          source={children}
          renderers={{
            table: ({ children }) => {
              return <Table responsive>{children}</Table>;
            },
            heading: (props) => {
              switch (props.level) {
                case 1:
                  return <h1>{props.children}</h1>;
                default:
                  return <h4>{props.children}</h4>;
              }
            },
            code: ({ language, value }) => {
              switch (language) {
                case 'info':
                  return <Alert>{value}</Alert>;
                case 'warning':
                  return <Alert color="warning">{value}</Alert>;
                case 'sql':
                  return <Editor>{value}</Editor>;
                default:
                  return (
                    <pre>
                      <code>{value}</code>
                    </pre>
                  );
              }
            },
          }}
        />
      </CardBody>
    </Card>
  );
};
