import './index.scss';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Col, Row, Container } from 'reactstrap';
import StickyNav from './sticky-nav';
import Note from './components/note';
import notes from './notes';
import api from './api';
import Header from './components/header';

const App = () => {
  const [provisioned, setProvisioned] = useState(false);

  useEffect(() => {
    api.provisionInstance().then(() => setProvisioned(true));
  }, []);

  if (!provisioned) {
    return <div>Provisioning database...</div>;
  }

  return (
    <div>
      <Header />
      <Container fluid>
        <Row>
          <Col md="2">
            <StickyNav
              selector=".note.card"
              links={notes.map((n) => n.title)}
            />
          </Col>
          <Col md="10">
            {notes.map(({ title, content }, i) => {
              return (
                <Note key={i} title={title}>
                  {content}
                </Note>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ReactDOM.render(<App />, document.querySelector('#root'));
