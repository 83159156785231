import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/sql/sql';
import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { Alert } from 'reactstrap';
import api from '../api';
import EditorFooter from './editor-footer';
import PagedResults from './paged-results';

export default ({ children }) => {
  const [value, setValue] = useState(children || '');
  const [err, setErr] = useState('');
  const [data, setData] = useState(null);

  const runQuery = async () => {
    const { data, error } = await api.runQuery(value);

    setData(data);
    setErr(error);
  };

  return (
    <div className="sql-editor">
      <div className="editor-wrapper">
        <div className="editor-left">
          <CodeMirror
            value={value}
            options={{
              mode: 'text/x-pgsql',
              theme: 'material',
              lineNumbers: true,
              tabMode: 'indent',
              tabSize: 2,
            }}
            onBeforeChange={(_editor, _data, newValue) => {
              setValue(newValue);
            }}
          />
          {err ? (
            <Alert className="code-error" color="danger">
              {err}
            </Alert>
          ) : null}
        </div>

        {data ? <PagedResults results={data} /> : null}
      </div>
      <EditorFooter runQuery={runQuery} value={value} setValue={setValue} />
    </div>
  );
};
